import { sendRequest } from "../../util/utils";

const getCurrentSong = async () => {
  const route = "/current_song",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
  return await sendRequest(route, request);
};

const getQueue = async () => {
  const route = "/queue",
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
  return await sendRequest(route, request);
};

const search = async (data) => {
  const route = `/search`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: data
    };
  return await sendRequest(route, request);
};

const addToQueue = async (data) => {
  // const data = id
  const route = `/add_to_queue`,
    request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: data,
    };
  return await sendRequest(route, request);
};

export default {
  getCurrentSong,
  search,
  addToQueue,
  getQueue
};
