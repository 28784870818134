import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableFooter,
  IconButton,
  TableContainer,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const SongList = (props) => {
  const [audioObject, setAudioObject] = useState(null);
  const [currentOnAir, setCurrentOnAir] = useState({ id: "", preview: "" });
  const [addedList, setAddedList] = useState([]);
  const { resultList, title, isQueue = false } = props;
  const maxString = 40;

  useEffect(() => {
    if (!!props.resultList) {
      const aux = [];
      props.resultList.map((result) => aux.push(result.added));
      setAddedList(aux);
    }
  }, [props.resultList]);

  const handlePlay = (preview, id) => {
    if (audioObject != null || currentOnAir.preview === preview) {
      audioObject.pause();
      setAudioObject(null);
      setCurrentOnAir({ preview: "", id: "" });
    }
    if (currentOnAir.preview !== preview) {
      setAudioObject(new Audio(preview));
      setCurrentOnAir({ preview, id });
    }
  };

  const addToQueue = async (index, id) => {
    const prevState = { ...addedList };
    const auxResults = { ...addedList, [index]: true };
    setAddedList(auxResults);
    const response = await api.addToQueue(JSON.stringify({ id: id }));
    if (!response.success) {
      setAddedList(prevState);
    }
  };

  useEffect(() => {
    if (audioObject != null) audioObject.play();
  }, [audioObject]);

  console.log("Im here");

  return (
    <div className={`d-flex flex-column align-items-center ${props.className}`}>
      <>
        <h3 className="text-white">{title}:</h3>
        {/* <div className="d-flex flex-wrap p-0 m-0 justify-content-between"> */}
        <TableContainer
          sx={{ maxHeight: "26rem", borderRadius: "1.7rem 1.7rem 0 0" }}
        >
          <Table
            className={`trackList ${isQueue ? "list_container-scroll" : ""}`}
            stickyHeader={isQueue}
          >
            <TableHead>
              <TableRow className="trackList-head">
                {!isQueue && <TableCell className="col-1" />}
                <TableCell
                  scope="col"
                  className={`${isQueue ? "col-7" : "col"}`}
                >
                  <b className="text-primary">Track</b>
                </TableCell>
                <TableCell
                  scope="col"
                  className={`${isQueue ? "col-5" : "col"}`}
                >
                  <b className="text-primary">Artist</b>
                </TableCell>
                {!isQueue && (
                  <TableCell scope="col" className="col-3"></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {resultList.map((song, i) => (
                <TableRow>
                  {!isQueue && (
                    <TableCell>
                      {/* <p className="text-white">{i}</p> */}
                      <img
                        className={``}
                        id={`img-${song.id}`}
                        src={song.cover}
                        alt=""
                        width="100%"
                        // onClick={() => handlePlay(song.preview, song.id)}
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <b className="text-white">
                      {song.song.length >= maxString - 10
                        ? `${song.song.slice(0, maxString - 10)}...`
                        : song.song}
                    </b>{" "}
                    <br />
                    <p className="text-white mb-0">
                      {song.album.length >= maxString
                        ? `${song.album.slice(0, maxString)}...`
                        : song.album}
                    </p>
                  </TableCell>
                  <TableCell>
                    <p className="text-white mb-0">
                      {song.artist.length >= maxString
                        ? `${song.artist.slice(0, maxString)}...`
                        : song.artist}
                    </p>
                  </TableCell>
                  {!isQueue && (
                    <TableCell>
                      <div className="d-flex justify-content-between">
                        <button
                          id={song.id}
                          className="btn btn-outline-primary"
                          disabled={addedList[i]}
                          onClick={() => addToQueue(i, song.id)}
                        >
                          {addedList[i] ? "Added" : "Add to queue"}
                        </button>
                        <IconButton
                          id={song.id}
                          className="bg-primary"
                          disabled={addedList[i]}
                          onClick={() => handlePlay(i, song.id)}
                        >
                          {currentOnAir.id === song.id ? (
                            <PauseIcon />
                          ) : (
                            <PlayArrowIcon />
                          )}
                        </IconButton>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter />
          </Table>
        </TableContainer>
        {/* </div> */}
      </>
    </div>
  );
};

export default SongList;
