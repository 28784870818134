import React from "react";
import Finder from "./routes/Finder";

const App = () => {
  return (
    <div>
      <Finder />
    </div>
  );
};

export { App };
