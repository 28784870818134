import React, { useEffect, useState } from "react";
import api from "../../../services/api";

const SongGrid = (props) => {
  const [audioObject, setAudioObject] = useState(null);
  const [currentOnAir, setCurrentOnAir] = useState({ id: "", preview: "" });
  const [addedList, setAddedList] = useState([]);
  const { resultList } = props;

  useEffect(() => {
    if (audioObject != null) {
      console.log("unsuscribing");
      audioObject.pause();
    }
  }, [props.clearFlag]);

  useEffect(() => {
    if (!!props.resultList) {
      const aux = [];
      props.resultList.map((result) => aux.push(result.added));
      setAddedList(aux);
    }
  }, [props.resultList]);

  const handlePlay = (preview, id) => {
    if (audioObject != null || currentOnAir.preview === preview) {
      audioObject.pause();
      setAudioObject(null);
      setCurrentOnAir({ preview: "", id: "" });
    }
    if (currentOnAir.preview !== preview) {
      setAudioObject(new Audio(preview));
      setCurrentOnAir({ preview, id });
    }
  };

  const addToQueue = async (index, id) => {
    const prevState = { ...addedList };
    const auxResults = { ...addedList, [index]: true };
    setAddedList(auxResults);
    const response = await api.addToQueue(JSON.stringify({ id: id }));
    if (!response.success) {
      setAddedList(prevState);
    }
  };

  useEffect(() => {
    if (audioObject != null) audioObject.play();
  }, [audioObject]);

  return (
    <div className="d-flex flex-column align-items-center">
      <>
        <h3 className="text-white">Results:</h3>
        <div className="d-flex flex-wrap p-0 m-0 justify-content-between">
          {resultList.map((song, i) => (
            <div
              className="d-flex flex-column col-md-4 col-12 align-items-center"
              key={song.id}
            >
              <div className="col p-4">
                <img
                  className={`rounded-circle cursor-pointer ${
                    currentOnAir.id === song.id ? "onAir" : ""
                  }`}
                  id={`img-${song.id}`}
                  src={song.cover}
                  alt=""
                  width="100%"
                  onClick={() => handlePlay(song.preview, song.id)}
                />
              </div>
              <p className="text-white p-0 m-0">
                {song.song.length >= 30
                  ? `${song.song.slice(0, 30)}...`
                  : song.song}
              </p>
              <p className="text-white p-0 m-0 font-14">{song.artist}</p>
              <br />
              <button
                id={song.id}
                className="btn btn-outline-primary"
                disabled={addedList[i]}
                onClick={() => addToQueue(i, song.id)}
              >
                {addedList[i] ? "Added" : "Add to queue"}
              </button>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default SongGrid;
