import React, { useState, useEffect } from "react";
import { useForm, useWatch, Controller } from "react-hook-form";
import '../../../assets/styles/custom.scss'


//Icons
import SearchIcon from "@mui/icons-material/Search";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PersonIcon from '@mui/icons-material/Person';
import AlbumIcon from '@mui/icons-material/Album';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const SearchForm = (props) => {
  const [advancedSearch, toggleAdvancedSearch] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    defaultValues: "",
  });

  const handleAdvanced = (e) => {
    toggleAdvancedSearch(e.target.checked);
  };

  const clearFields = () => {
    clearAdvanced();
    clearGeneral();
    props.onChangeView(!props.changeFlag)
  }

  const clearAdvanced = () => {
    setValue("track", "");
    setValue("artist", "");
    setValue("album", "");
    setValue("genre", "");
    setValue("year", "");
  }

  const clearGeneral = () => {
    setValue("general", "");
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const values = getValues()

    const data = {
    general: values.general || "",
    track: values.track || "",
    artist: values.artist || "",
    album: values.album || "",
    genre: values.genre || "",
    year: values.year || "",
  }
    props.handleSubmit(data);
  }
  
  const handleUserKeyPress = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      // e.preventDefault();
      handleSubmit(onSubmit(e))(); // this won't be triggered
    }
};

  useEffect (() => {

    if(advancedSearch){
        clearGeneral();
    } else {
        clearAdvanced();
    }
  }, [advancedSearch])

  return (
    <div className="col-md-9 col-12 mb-1">
      <div className="d-flex flex-column align-items-center">
        <div className="col-md-10 col-12">
          <h1 className="text-white">Spotify Search</h1>
        </div>
        <div className="col-md-10 col-12">
          <p className="text-white font-12">
            Type a song name and click on <b>"Search".</b>
            <br />
            Then, click on anypicture from the results to play 30 seconds
            preview
            <br />
            To search more in detail, click <b>"Advanced"</b>
            <br />
            Click the "Add to queue" button to send it to my queue
          </p>
        </div>
        <form className="col-md-10 col-12" onSubmit={e => onSubmit(e)}>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              onChange={handleAdvanced}
              id="advanced-search"
            />
            <label
              className="form-check-label text-white font-weight-bold"
              htmlFor="advanced-search"
            >
              Advanced
            </label>
          </div>

          {advancedSearch ? (
            <>
              <div className="input-group mb-1">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-primary" id="search-by-song">
                    <MusicNoteIcon  className="text-white"/>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Song"
                  aria-label="Song"
                  aria-describedby="search-by-song"
                  onKeyPress={handleUserKeyPress}
                  {...register("track")}
                />
              </div>
              <div className="input-group mb-1">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-primary" id="search-by-artist">
                    <PersonIcon className="text-white"/>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Artist"
                  aria-label="Artist"
                  aria-describedby="search-by-artist"
                  onKeyPress={handleUserKeyPress}
                  {...register("artist")}
                />
              </div>
              <div className="input-group mb-1">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-primary" id="search-by-album">
                    <AlbumIcon  className="text-white"/>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Album"
                  aria-label="Album"
                  aria-describedby="search-by-album"
                  onKeyPress={handleUserKeyPress}
                  {...register("album")}
                />
              </div>
              <div className="input-group mb-1">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-primary" id="search-by-genre">
                    <LibraryMusicIcon  className="text-white"/>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Genre"
                  aria-label="Genre"
                  aria-describedby="search-by-genre"
                  onKeyPress={handleUserKeyPress}
                  {...register("genre")}
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-primary" id="search-by-year">
                    <CalendarTodayIcon  className="text-white"/>
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Year"
                  aria-label="Year"
                  aria-describedby="search-by-year"
                  onKeyPress={handleUserKeyPress}
                  {...register("year")}
                />
              </div>
            </>
          ) : (
            <>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text bg-primary" id="general">
                    <SearchIcon  className="text-white"/>
                  </span>
                </div>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-general"
                  onKeyPress={handleUserKeyPress}
                  {...register("general")}
                />
              </div>
            </>
          )}
          <div className="d-flex justify-content-end">
              <button className="btn btn-secondary text-white font-weight-bold bg-transparent" onClick={clearFields}>Clear</button>
              <button type="submit" className="btn btn-primary text-white font-weight-bold" onClick={() => props.onChangeView(!props.changeFlag)}>Search</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchForm;
