import React from "react";
import SongList from "./SongList";

function QueuedSongs({ ...props }) {
  return (
    <div className={`container-queue_list ${props.className}`}>
      <SongList resultList={props.queue} title="Queue" isQueue={true} ></SongList>
    </div>
  );
}

export default QueuedSongs;
