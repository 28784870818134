import React, { useState, useEffect } from "react";

const CurrentSong = (props) => {
  const { currentSong } = props;
  const [audioObject, setAudioObject] = useState(
    new Audio(currentSong.preview)
  );
  const [playing, setPlaying] = useState(props.playing || false);

  useEffect(() => {
    if (audioObject != null) {
      console.log("unsuscribing");
      audioObject.pause();
    }
  }, [props.clearFlag]);

  useEffect(() => {
    if (playing) audioObject.play();
    else audioObject.pause();
  }, [playing]);

  const tooglePlay = () => {
    setPlaying(!playing);
  };
  const handlePlay = props.handlePlay || tooglePlay;

  return (
    <div className={`d-flex flex-column align-items-center ${props.className}`}>
      <h1 className="text-white">Currently playing:</h1>
      <img
        className="rounded-circle cursor-pointer"
        src={currentSong.cover}
        alt=""
        height="300"
        onClick={handlePlay}
      />
      <h3 className="text-primary">{currentSong.artist}</h3>
      <h5 className="text-primary">{currentSong.album}</h5>
      <p className="text-primary">{currentSong.song}</p>
    </div>
  );
};

export default CurrentSong;
