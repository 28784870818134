import React, { useState, useEffect } from "react";
import api from "../../services/api";
import Loader from "../../components/Loader";
import SearchForm from "./components/SearchForm";
import CurrentSong from "./components/CurrentSong";
import SongGrid from "./components/SongGrid";
import SongList from "./components/SongList";

//Material resourcess
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GridViewIcon from "@mui/icons-material/GridView";
import TableRowsIcon from "@mui/icons-material/TableRows";
import QueuedSongs from "./components/QueuedSongs";

const Finder = () => {
  const [currentSong, setCurrentSong] = useState(null);
  const [currentQueue, setCurrentQueue] = useState(null);
  const [resultList, setResultList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [meta, setMeta] = useState({
    hasPrev: false,
    hasNext: false,
    total: 0,
  });
  const [persistData, setPersistData] = useState(null);
  const [selectedView, setSelectedView] = useState("grid");

  const [clearFlag, setClearFlag] = useState(false);

  useEffect(() => {
    getCurrentSong();
    // getQueue();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      getCurrentSong();
      // getQueue();
    }, 10000);

    return () => clearInterval(intervalId); //This is important
  });

  const handleSubmit = async (data) => {
    const searchData = {
      ...data,
      page: 1,
      rowsPerPage: rowsPerPage,
    };
    setPage(1);
    setPersistData(searchData);
    search(searchData);
  };

  const search = async (data) => {
    setLoading(true);
    setResultList(null);
    const response = await api.search(JSON.stringify(data));
    if (response.success) {
      const { tracks, hasPrev, hasNext, total } = response.data;
      setMeta({ hasNext, hasPrev, total });
      if (tracks.length > 0) {
        const parsedTracks = [];
        tracks.map((track) => parsedTracks.push({ ...track, added: false }));
        setResultList(parsedTracks);
      }
    }
    setLoading(false);
  };

  console.log(currentQueue)

  // const getQueue = async () => {
  //   const response = await api.getQueue();
  //   if (response.success) {
  //     if (!currentQueue && currentQueue.length <= 0 )
  //       console.log({ ...response.data });
  //   }
  //   setLoading(false);
  // };

  const getCurrentSong = async () => {
    const songResponse = await api.getCurrentSong();
    const queueResponse = await api.getQueue();
    // console.log(response);
    if (songResponse.success) {
      if (!currentSong || currentSong.id !== songResponse.data.id) {
        setCurrentSong({ ...songResponse.data });
        if(queueResponse.data.length > 0)
          setCurrentQueue([...queueResponse.data])
      }
    }
    setLoading(false);
  };

  const goToPage = (page) => {
    console.log(page);
    setPage(page);
    const data = { ...persistData, page: page };
    search(data);
  };

  const handleView = (event) => {
    setSelectedView(event.target.value);
  };
  console.log(selectedView);

  return (
    <>
      <div className="container d-flex flex-column align-items-center col-12">
        <SearchForm
          handleSubmit={handleSubmit}
          onChangeView={setClearFlag}
          changeFlag={clearFlag}
        />
        {loading && (
          <div className="pt-4 mt-4 d-flex justify-content-center">
            <Loader />
          </div>
        )}
        <div className="d-flex row col-12 mt-4">
          {!!currentSong &&
            (!resultList || resultList.length <= 0) &&
            !loading && (
              <CurrentSong
                className={`col-12 ${ currentQueue?.length > 0 ? 'col-md-6' : ""}`}
                currentSong={currentSong}
                clearFlag={clearFlag}
              />
            )}
          {currentQueue?.length > 0 &&
            (!resultList || resultList.length <= 0) &&
            !loading && (
              <QueuedSongs
                className="col-12 col-md-6"
                queue={currentQueue}
              />
            )}
        </div>
        {!!resultList && (
          <>
            <div className="row col-2">
              {/* <div class="btn-group btn-group-toggle" data-toggle="buttons">
                <input
                  type="radio"
                  class="btn-check"
                  name="options"
                  id="grid"
                  value="grid"
                  autocomplete="off"
                  onChange={(e) => handleView(e)}
                  checked={selectedView === "grid"}
                />
                <label class="btn btn-outline-primary" for="grid">
                  <GridViewIcon />
                </label>
                <input
                  type="radio"
                  class="btn-check"
                  name="options"
                  id="list"
                  value="list"
                  autocomplete="off"
                  onChange={(e) => handleView(e)}
                  checked={selectedView === "list"}
                />
                <label class="btn btn-outline-primary" for="list">
                  <TableRowsIcon />
                </label>
              </div> */}
            </div>
            {selectedView === "grid" && (
              <SongGrid resultList={resultList} clearFlag={clearFlag} />
            )}
            {selectedView === "list" && (
              <SongList className="col-10" resultList={resultList} clearFlag={clearFlag} title="Results" />
            )}
            <hr className="color-primary" />
            <div className="d-flex justify-content-evenly col-12 my-4 pb-4">
              <button
                className="btn btn-outline-primary"
                style={{ borderRadius: "50%", padding: "0.75rem" }}
                disabled={!meta.hasPrev}
                onClick={() => goToPage(page - 1)}
              >
                <ArrowBackIosNewIcon />
              </button>
              <h4 className="text-primary mb-0 align-self-center">{page}</h4>
              <button
                className="btn btn-outline-primary"
                style={{ borderRadius: "50%", padding: "0.75rem" }}
                disabled={!meta.hasNext}
                onClick={() => goToPage(page + 1)}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Finder;
